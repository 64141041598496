.teamCard h5,
.teamCard p {
  margin: 0 !important;
}

.UnderlineHr {
  color: #cdd3f8;
  margin: 10px 0 !important;
}

.memberCardText p {
  font-family: "Noto Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  margin-bottom: 5px;
  margin-top: 10px;
}

.teamCard {
  width: 95%;
  cursor: pointer;
  margin: 10px auto;
  box-sizing: border-box;
}

.teamCard:hover {
  box-shadow: 0 0 4px 2px #227dc3;
}

.memberCardText h5 {
  font-family: "Noto Sans";
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  margin-bottom: 0px !important;
}

.cardHeaderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.piechartSvgContainer {
  width: 65px;
  height: 65px;
}

.PiechartContainerMain {
  margin-top: 15px !important;
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.ImportantHeading {
  font-family: Noto Sans !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 21px !important;
  color: #0a1f41 !important;
}

.dateText {
  display: flex;
  column-gap: 10px;
}

.CardBtnsSection {
  display: flex;
  justify-content: space-between;
  margin: 15px 0 0 !important;
}

.cardBtn {
  width: 49% !important;
  text-transform: capitalize !important;
}

.AmountText {
  font-weight: 600;
  font-size: 14px;
}

.sliderMaindiv > div > div > div {
  width: 33% !important;
}

/* .EachSubscriptionCard>div { */
/* width: 33% !important; */
/* margin: 0 !important; */
/* } */

.subscSubHeading {
  margin-left: 10px !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 27px !important;
  color: #0a1f41;
}

.loginModalWidth {
  position: relative;
}

.closebtn {
  position: absolute !important;
  right: 5px;
  top: 5px;
}

.closebtn svg {
  font-size: 21px;
  font-weight: 800;
  color: black;
  z-index: 1;
}

.addMembersuccess {
  margin: 15px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 92%;
  justify-content: center;
  height: 90%;
  text-align: center;
  row-gap: 10px;
}

.addMembersuccess button {
  margin: 20px 0 10px;
}

.successPageSubText {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  text-align: center !important;
  color: #6b778c;
}

.imagePageText1 {
  margin-top: 10px !important;
  text-align: center;
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 34px !important;
  width: 84%;
  height: auto;
}
